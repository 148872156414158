import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import hero from "../public/hero.jpg";
import lllogo from "../public/lli-logo.svg";
import { GraphQLClient, gql } from "graphql-request";

import styles from "../styles/Home.module.css";
import { GetStaticProps } from "next";

const graphcms = new GraphQLClient(
  process.env.NEXT_PUBLIC_GRAPHCMS_URL as string
);

interface HomeProps {
  texts: { id: string; name: string; content: string }[];
  regDetails: {
    eventStart: string;
    registrationEnd: string;
  };
}

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const { texts, registrationDetails } = await graphcms.request(gql`
    {
      texts(where: { page: "home" }) {
        id
        name
        content
      }
      registrationDetails {
        id
        eventStart
        registrationEnd
      }
    }
  `);

  return {
    props: { texts, regDetails: registrationDetails[0] },
  };
};

export default function Home(props: HomeProps) {
  const { texts, regDetails } = props;

  const eventStartDate = new Date(regDetails.eventStart);

  const text = (name: string) =>
    texts.find((t) => t.name === name)?.content || "";

  return (
    <div>
      <Head>
        <title>Monadnock Full Throttle Triathlon</title>
        <meta
          name="description"
          content="A triathlon fundraiser to support Little Lambs Intl"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div>
        <div className={styles.hero}>
          <Image
            src={hero}
            alt="Hero Banner Image of racers running into water"
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            priority
          />
          <h1>
            Monadnock Full <br /> Throttle Triathlon
          </h1>
          <div>
            <Link href="/register">
              <a className={styles.cta}>Register Now</a>
            </Link>
          </div>
        </div>
        <section className={styles.raceDetail}>
          <h1>Monadnock Full Throttle Triathlon</h1>
          <h3>
            {eventStartDate.toLocaleDateString("en", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </h3>

          <hr />
          <h2>Race Day Timeline</h2>
          {renderDetailItem(
            "Registration - 7 AM at Monadnock Christian Ministries Campground- 26 Poole Rd. Jaffrey, NH",
            text("registration_description")
          )}
          {renderDetailItem("Swim - 9 AM", text("swim_description"))}
          {renderDetailItem("Bike", text("bike_description"))}
          {renderDetailItem("Hike", text("hike_description"))}
          {renderDetailItem(
            "Water Stations",
            text("water_station_description")
          )}
          {renderDetailItem("Refuel!", text("refuel_description"))}
          {renderDetailItem(
            "Spectators",
            `
          Spectators can come watch the race and cheer them on. Race starts at 9am at Thorndike Pond beach. You will need to park at Gilson State Park across the street.
Spectators may also come to the campground on Poole Rd. This is the transition area for the bike and hike and also the finish line! Award ceremony around 12:15. Just please be aware of the racers and stay out of the way!

`
          )}

          <iframe
            src="https://www.google.com/maps/d/embed?mid=zdWx7yjXwyhw.k1FcH453JfSY"
            width="100%"
            height="480"
            style={{ marginTop: "36px" }}
          />
        </section>
        <Link href="/register">
          <a className={styles.registerBanner}>
            <span>Register now for the Triathlon</span>
          </a>
        </Link>
        <Info />
      </div>
    </div>
  );
}

function renderDetailItem(title: string, details: any) {
  return (
    <div>
      <h4>{title}</h4>
      <div>{details}</div>
    </div>
  );
}

function Info() {
  return (
    <section className={styles.info}>
      <h1> Fundraising for Little Lambs International</h1>
      <hr />
      <div className={styles.wrapper}>
        <div>
          <p>
            Little Lambs International plans several trips throughout the course
            of a year for volunteers to come and experience Guatemala. These
            groups travel to the construction site of our campus style orphanage
            and help, hands on, with construction and community outreach. You
            too can join us and make a difference.
          </p>
          <p className="fineprint">
            To learn more about the Little Lambs International mission, visit{" "}
            <a href="http://www.littlelambsintl.org/">
              http://www.littlelambsintl.org
            </a>
            .
          </p>
        </div>
        <div className={styles.logoWrapper}>
          <a href="http://www.littlelambsintl.org/">
            <Image
              src={lllogo}
              alt="Little Lambs International Logo and link to website"
              height={300}
              width={240}
            />
          </a>
        </div>
      </div>
    </section>
  );
}
